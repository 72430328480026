import React from "react"
import { ModularPage } from "../components"
import { graphql } from "gatsby"

export default ({ data }) => <ModularPage data={data} />

export const query = graphql`
  query PageQuery($slug: String!) {
    datoCmsPage(slug: { eq: $slug }) {
      slug
      title
      ...SeoMetaTags
      ...HeaderImage
      ...Modules
    }
  }
`
